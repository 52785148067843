import React, { useState } from "react";
import styles from "./ProductModal.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//images
import {
  cartIco,
  checkIco,
  notice,
  phoneWhiteIco,
} from "../../../../ImageConstants/imageConstants";

//commons
import { GoodSlider } from "../../GoodSlider/GoodSlider";
import CharacterTable from "../../CharacterTable";
import GoodTable from "../../GoodTable";

//utils
import { addGoodsInBasket } from "../../../../Utils/addGoodsInBasket";

//redux
import {
  setModalWindowInfo,
  setModalWindowChildrenType,
  setModalWindowStatus,
} from "../../../../ReduxToolkit/App/app";

export const ProductModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.dataGetUserBack);

  const language = useSelector((state) => state.app.language);

  const product = useSelector((state) => state.app.modalWindowInfo);

  let {
    id,
    available,
    name,
    specificationsModifive,
    price,
    is_contract_price,
    currency,
    specifications,
    variations,
  } = product;
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const [basketGoodVariations, setBasketGoodVariations] = useState([]);

  return (
    <div className={styles.modal}>
      <div className={styles.top}>
        <div className={styles.img}>
          <GoodSlider good={product} modal />
        </div>
        <div className={styles.info}>
          <h4 className={styles.title_top}>{name}</h4>

          <div className={styles.status}>
            {" "}
            {!is_contract_price ? (
              <>
                <img src={checkIco} alt="check" /> <span>{available}</span>
              </>
            ) : (
              <>
                <img src={notice} alt="warning" />
                {/* {status} */} {t("notice")}
              </>
            )}
          </div>

          {!is_contract_price ? (
            <>
              <div className={styles.price}>
                {minPrice !== 0 && (
                  <>
                    {minPrice} <span>{currency}</span>{" "}
                    <span className={styles.hyphen}>-</span>
                  </>
                )}
                {variations?.length > 0 ? maxPrice : price}{" "}
                <span>{currency}</span>
              </div>
              {variations?.length > 0 && (
                <div className={styles.table_main}>
                  <GoodTable
                    currency={currency}
                    idGood={id}
                    array={variations}
                    specificationsGood={specifications}
                    priceGood={price}
                    setMaxPrice={setMaxPrice}
                    setMinPrice={setMinPrice}
                    basketGoodVariations={basketGoodVariations}
                    setBasketGoodVariations={setBasketGoodVariations}
                  />{" "}
                </div>
              )}

              <button
                className={styles.btn}
                onClick={() => {
                  if(variations.length == 0) addGoodsInBasket(id, false, 1, dispatch, language)
                  else basketGoodVariations.map((quantity, variationId) => addGoodsInBasket(variationId, true, quantity, dispatch, language));
                }}
              >
                {t("productModal.addBasket")}
                <img src={cartIco} alt="cart" />
              </button>
            </>
          ) : (
            <>
              <div className={styles.price}>{t("requestPrice")}</div>
              <button
                className={styles.btn}
                onClick={() => {
                  dispatch(setModalWindowInfo(product));
                  dispatch(setModalWindowStatus(true));
                  dispatch(setModalWindowChildrenType("requestPrice"));
                }}
              >
                {t("findCost")}
                <img src={phoneWhiteIco} alt="phone" />
              </button>
            </>
          )}
        </div>
      </div>
      {specificationsModifive.length > 0 && (
        <div className={styles.characteristics}>
          <h5 className={styles.title}>Характеристики</h5>
          <CharacterTable
            specifications={specificationsModifive}
            language={language}
          />
        </div>
      )}
    </div>
  );
};
