import { setModalWindowChildrenType, setModalWindowStatus, setModalWindowInfo } from "../ReduxToolkit/App/app";
import { Cookies } from 'react-cookie';
import { getBasketGoodsBack } from "../ReduxToolkit/Basket/Basket";

export const addGoodsInBasket = (id, isVariation, quantity, dispatch, language, needForm = true) => {
  if(quantity !== 0) {
    const cookies = new Cookies();
    var basket = cookies.get('basket') ?? [];

    var productInBasket = false;
    if(basket) {
      basket.forEach(function(item, index, object) {
        if((item.id == id) && (item.isVariation == isVariation)) {
          if(item.quantity+quantity <= 0) object.splice(index, 1);
          else item.quantity = item.quantity+quantity;
          productInBasket = true;
        }
      });
    }
    if(!productInBasket) basket.push({ id: id, isVariation: isVariation, quantity: quantity });

    var expires = new Date();
    expires.setDate(expires.getDate()+365);
    cookies.set('basket', basket, { path: '/', expires });

    dispatch(getBasketGoodsBack({ lang: language }));

    if(needForm) {
      dispatch(
        setModalWindowInfo({
          title: "modalWindows.success.addGoodInBasket.title",
        })
      );
      dispatch(setModalWindowChildrenType("success"));
      dispatch(setModalWindowStatus(true));
    }
  }
};